import { useIntl } from 'react-intl';
import Button, { SECONDARY } from '../Button/Button';
import React from 'react';
import styles from './InstallPWAButton.module.scss';
import { useSelector } from 'react-redux';

const InstallPWAButton = () => {
  const deferredPWAInstallPrompt = useSelector(({ ui }) => ui.deferredPWAInstallPrompt);
  const intl = useIntl();

  const install = () => {
    if (window?.deferredPWAInstallPrompt) window.deferredPWAInstallPrompt.prompt();
  };

  if (!deferredPWAInstallPrompt) return null;
  return (
    <div className={styles.root}>
      <Button variation={SECONDARY} onClick={install}>
        {intl.formatMessage({ id: 'common.pwa.installButton' })}
      </Button>
    </div>
  );
};

export default InstallPWAButton;
